<template>
  <div id="app">
    <error-handler/>
    <loading-animation/>

    <router-view/>
  </div>
</template>

<script>
import ErrorHandler from '@/components/utility/ErrorHandler.vue'
import LoadingAnimation from '@/components/utility/LoadingAnimation.vue'
export default {
  name: 'App',
  components: {
    ErrorHandler,
    LoadingAnimation,
  },
  
}
</script>

<style lang="scss">
html, body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
