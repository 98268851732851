<template>
  <div v-if="isLoading" class="loading-animation-container">
    <div class="centered">
      <div class="blob-1"></div>
      <div class="blob-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingAnimation',
  computed: {
    isLoading() {
      return this.$store.getters.loading > 0
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.loading-animation-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(black, 0.8);
  // height: 200px;
  z-index: 999999;
}

.centered{
	width: 100%;
	position:absolute;
	top:50%;
	left:53%;
	transform:translate(-50%,-50%);
	// filter: blur(10px) contrast(20);
}
.blob-1{
	width:120px;
	height:120px;
	position:absolute;
	background:$secondary;
	border-radius:50%;
	top:50%;left:50%;
	transform:translate(-50%,-50%);
}
.blob-2{
	width:70px;
	height:70px;
	position:absolute;
	background: $secondary;
	border-radius:50%;
	top:50%;left:50%;
	transform:translate(-50%,-50%);
}
.blob-1{
	left:20%;
	animation:osc-l 2.5s ease infinite;
}
.blob-2{
	left:80%;
	animation:osc-r 2.5s ease infinite;
	background: $secondary;
}
@keyframes osc-l{
	0%{left:20%;}
	50%{left:50%;}
	100%{left:20%;}
}
@keyframes osc-r{
	0%{left:80%;}
	50%{left:50%;}
	100%{left:80%;}
}

</style>