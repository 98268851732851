import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: 0,
  },
  mutations: {
    loading(state, value) {
      if(value == true) {
        state.loading++
      }
      if(value == false) {
        state.loading--
      }
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    loading: state => state.loading,
  }
})
